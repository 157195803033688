.landing_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  min-height: 700px;
  width: 100%;
  padding: 24px 5vmax;
  text-align: center;
  position: relative;
  min-height: calc(100vh - 55px);

  .container {
    display: flex;
    flex-direction: row;
    // align-items: center;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 96px;
    flex: 1;

    .text__section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      gap: 72px;

      .text_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 28px;
        max-width: 600px;
        min-width: 500px;
        z-index: 100;
        flex: 1;

        h1 {
          font-size: 72px;
          text-align: start;
          font-weight: 600;
          font-family: 'Poppins';
          color: #F6F7FA
        }
        h6 {
          font-size: 18px;
          text-align: start;
          font-weight: 600;
          color: #F6F7FA;
          letter-spacing: 0.05px;
          line-height: 140%;
        }
        p {
          font-size: 14px;
          color: #8f929e;
          text-align: start;
          font-weight: 500;
          letter-spacing: 0.05px;
          line-height: 140%;
        }
      }
    }

    .video__responsive {
      position: relative;
      height: 100%;
      width: 100%;
      z-index: 1000;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 1;
      max-width: 800px;
      margin: auto;

      iframe {
        margin-top: -96px;
        border: 2px solid var(--border-color);
        border-radius: 8px;
        filter: drop-shadow(0px 20px 20px hsla(230, 9%, 7%, 1));
        width: 100%;
        height: auto;
        aspect-ratio: auto 560 / 315;
      }
    }

    @media screen and (max-width: 1400px){
      .video__responsive {
        flex: 2;
      }
    }

    @media screen and (max-width: 1200px) {
      gap: 48px;
    }

    @media screen and (max-width: 1100px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 28px;

      .video__responsive {
        margin-top: 0;
        justify-content: flex-start;

        iframe {
          margin-top: 0;
          width: 80%;
          height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 24px;
    min-height: calc(100vh - 48px);

    .container {
      .text__section {
        .text_container {
          h1 {
            font-size: 58px;
          }
          h6 {
            font-size: 16px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-height: 900px) {
    .container {
      .video__responsive {
        iframe {
          margin-top: 0;
          filter: none;
        }
      }

      .text__section {
        .text_container {
          gap: 24px;

          h1 {
            font-size: 48px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    .container {
      .video__responsive {
        margin-top: 0;
        justify-content: flex-start;

        iframe {
          margin-top: 0;
          width: 100%;
          height: auto;
          filter: none;
        }
      }

      .text__section {
        .text_container {
          min-width: unset;
          margin-top: 20px;
          gap: 12px;

          h1 {
            font-size: 36px;
          }

          h6 {
            font-size: 14px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .button_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      min-width: 156px;
      color: #fff;
      text-align: center;
      background-color: #262626;
      background-image: linear-gradient(#262626,#262626);
      border-radius: 28px;
      padding: 14px 36px;
      font-family: Gotham,sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      transition: all .2s;

      transform: translateZ(0);
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      img {
        margin-bottom: 2px;
      }

      &:hover {
        color: #fff;
        -webkit-text-fill-color: inherit;
        background-image: none;
        transform: scale(1.05);

        &::before {
          opacity: 1;
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: block;
        background-image: -webkit-gradient(linear,left top,right top,from(#228bbc),to(#002c81));
        background-image: linear-gradient(90deg,#228bbc,#002c81);
        opacity: 0;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
        mix-blend-mode: lighten;
      }
    }
    @media screen and (max-width: 767px) {
      margin-top: 12px;

      .button {
        background-image: linear-gradient(90deg,#228bbc,#002c81);
      }
    }

    @media screen and (max-width: 500px) {
      .button {
        min-width: 120px;
        padding: 10px 14px;
        font-size: 12px;
      }
    }

    // @media screen and (max-height: 667px) {
    //   .button {
    //     padding: 8px 10px;
    //     font-size: 11px;
    //     min-width: unset;
    //   }
    //   margin-top: 0;
    // }
  }

  .alliance_outline {
    position: absolute;
    top: 25%;
    right: 10px;
    z-index: 10;
    transition: all 1s ease-in;

    @media (max-width: 1500px) {
      width: 750px;
    }
    @media (max-width: 1400px) or (max-height: 1000px) {
      width: 650px;
      right: 50px;
    }
    @media (max-height: 900px) {
      width: 650px;
    }
    @media (max-height: 775px) {
      top: 150px;
    }
    @media (max-height: 700px) {
      width: 600px;
      top: 100px;
    }
    @media (max-width: 1200px) {
      width: 650px;
      right: -50px;
    }
    @media (max-width: 1150px) {
      width: 550px;
      right: -50px;
    }
    @media (max-width: 1000px) {
      width: 550px;
      right: -50px;
      top: 100px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }

  .blob_1 {
    position: absolute;
    width: 528.92px;
    height: 527.27px;
    left: -100px;
    top: 65.41px;

    background: radial-gradient(
      30.8% 30.8% at 57.79% 54.71%,
      rgba(1, 200, 218, 0.24) 0%,
      rgb(2, 172, 210, 0.24) 48.71%,
      rgb(0, 216, 193, 0.24) 78.16%,
      rgba(1, 200, 218, 0.24) 100%
    );
    filter: blur(100px);
    rotate: 45deg;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .blob_2 {
    position: absolute;
    width: 315.58px;
    height: 195.27px;
    left: -100px;
    bottom: 100px;

    background:
      linear-gradient(
        137.64deg,
      rgba(43, 24, 103, 0.3) 26.68%,
      rgba(0, 216, 193, 0.3) 55.42%,
      rgba(0, 213, 237, 0.3) 72.8%,
      rgba(2, 172, 210, 0.3) 85.68%
      );
    filter: blur(75px);
    transform: rotate(180deg);

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .blob_3 {
    position: absolute;
    width: 177.99px;
    height: 110.13px;
    right: 50px;
    top: 0px;

    background:
      linear-gradient(
        137.64deg,
        rgba(1, 200, 218, 0.7) 26.68%,
        rgba(2, 172, 210, 0.7) 55.42%,
        rgba(0, 216, 193, 0.7) 72.8%,
        rgba(93, 106, 167, 0.7) 85.68%
      );
    filter: blur(75px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .ellipse_1 {
    position: absolute;
    width: 444.23px;
    height: 328.23px;
    right: -193.9px;
    top: 556.93px;

    background: radial-gradient(
      30.8% 30.8% at 57.79% 54.71%,
      rgba(1, 180, 150, 0.24) 0%,
      rgba(0, 213, 237, 0.24) 48.71%,
      rgba(2, 172, 210, 0.24) 78.16%,
      rgba(1, 200, 218, 0.24) 100%
    );
    filter: blur(326px);
    transform: rotate(-60deg);
  }

  .ellipse_3 {
    position: absolute;
    width: 375.5px;
    height: 375.96px;
    border-radius: 50%;
    right: 300px;
    top: 324.18px;
    background: linear-gradient(
      90deg,
      rgba(1, 200, 218, 0.4) 10%,
      rgba(0, 216, 193, .3) 15%,
      rgba(2, 172, 210, 0.6) 100%
    );
    filter: blur(125px);
    transform: rotate(0deg) scale(1);
    animation: breathe 14s linear infinite;
    z-index: -1;
  }

  @keyframes breathe {
    0% {
      transform: rotate(0deg) translate(30px) scale(1, 1);
    }

    50% {
      transform: rotate(180deg) translate(30px) scale(1.56, 1);
    }

    100% {
      transform: rotate(360deg) translate(30px) scale(1, 1);
    }
  }

  .ellipse_2 {
    position: absolute;
    width: 626px;
    height: 214px;
    right: -476.42px;
    top: 321.93px;

    background:
      radial-gradient(
        30.8% 30.8% at 57.79% 54.71%,
      rgba(1, 180, 150, 0.24) 0%,
      rgba(0, 213, 237, 0.24) 48.71%,
      rgba(2, 172, 210, 0.24) 78.16%,
      rgba(1, 200, 218, 0.24) 100%
      );
    filter: blur(90px);
    transform: rotate(15deg);
  }

  .ellipse_4 {
    position: absolute;
    width: 832px;
    height: 589px;
    left: 912px;
    top: -119px;

    background: #111c1c;
    filter: blur(210px);
  }

  .integrations {
    // position: absolute;
    bottom: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #8f929e;
      letter-spacing: 0.05px;
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 14px;
      .icon_only {
        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    // @media screen and (max-height: 760px) {
    //   bottom: -112px;
    // }

    @media screen and (max-width: 500px) {
      .icons {
        .icon_only {
          img {
            width: 22px !important;
            height: 22px !important;

            &.size__override {
              width: 32px !important;
              height: 32px !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 300px) {
      h3 {
        font-size: 14px;
      }
      .icons {
        width: 100%;
        flex-wrap: wrap;
      }
    }
  }

  .isMobile {
    position: relative;
    bottom: 0;
    gap: 8px;
    margin-bottom: 12px;

    @media screen and (max-height: 700px) {
      gap: 4px;
    }
  }
}
