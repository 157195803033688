.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  color: #fff;
  z-index: 1000;
  transition: transform 0.5s;

  .left_container {
    display: flex;
    align-items: center;
    gap: 26px;
  }

  .logo_container {
    display: flex;
    align-items: center;

    .logo {
      max-height: 40px;
      margin-right: 12px;
    }

    h3 {
      font-size: 22px;
      font-weight: 800;
      letter-spacing: 0.25px;
    }

    @media screen and (max-width: 767px) {
      h3 {
        display: none;
      }
    }
  }

  .button_container {
    display: flex;
    align-items: center;
    gap: 24px;

    .non_button_links {
      display: flex;
      align-items: center;
      // gap: 24px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 28px;
        font-weight: 500;

        @media screen and (max-width: 1100px) {
          margin: 0 12px;
        }
      }

      .icon_only {
        min-width: unset;
      }
    }

    .terra_link {
      height: 30px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0.5rem 1rem;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
      border: 1px solid #c1c1c1;
      border-radius: 25px;
      background: transparent;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      img {
        height: 14px;
      }

      @media screen and (max-width: 500px) {
        span {
          display: none;
        }
      }

      &:hover {
        background: hsla(245, 97%, 62%, 25%);
        border: 1px solid var(--accent-color);
        transform: scale(1.05);

        .arrow{
          animation: .75s infinite a;
        }
      }
    }

    @keyframes a {
      50% {
        transform: translateX(2px)
      }
    }

    @media screen and (max-width: 1100px) {
      gap: 12px;
    }
  }
}
