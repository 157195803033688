@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  // --accent-color: #228BBC;
  // --accent-color: #4672ED;
  --accent-color: #2aa5de;

  --accent-color-darkened: hsl(245, 90%, 55%);

  --bg: #000000;
  --font-color: #FFFFFF;
  --font-color-muted: #E8E8E8;
  --font-color-secondary: #8A8AA0;

  --border-color: #393939;

  --card-bg: #17191D;
  --card-header: #272A2F;
  --card-header-darkened: hsl(218, 9%, 14%);

  --input-bg: #2A2B31;
  --input-bg-darkened: hsl(231, 8%, 15%);

  // old colors
  // --card-header: #21212C;
  // --input-bg: #1F1F2C;
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  ::-webkit-scrollbar {
    display: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "tnum";
  color: var(--font-color);
  background: var(--bg);
  font-size: 14px;
  font-family: "Gotham", sans-serif;
  overflow-y: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@import "_fonts.scss";