.mobile_navigation {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;

  .left_container {
    display: flex;
    align-items: center;
    gap: 26px;

    .logo_container {
      display: flex;
      align-items: center;

      .logo {
        max-height: 28px;
        margin-right: 12px;
      }

      h3 {
        font-size: 22px;
        font-weight: 800;
        letter-spacing: 0.25px;
      }

      @media screen and (max-width: 767px) {
        h3 {
          display: none;
        }
      }
    }
  }
}

.menu_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }

  img {
    width: 24px;
    color: #000;
  }
}

.menu_btn_inner {
  min-width: 0 !important;
  padding: 0 !important;
  margin-left: auto;

  path {
    fill: var(--text-color-primary) !important;
  }
}

.drawer {
  padding: 20px;
  padding-left: 32px;
  display: flex;
  width: 80%;
  flex-direction: column;
  background-color: rgba(25, 28, 31, 1) !important;
  color: #F6F7FA !important;

  .top {
    display: flex;
    flex-direction: row;
  }
}

.label {
  color: #8f929e !important;
  font-size: 14px;
}

.menu_label {
  margin-top: 32px;
}

.menu_heading {
  color: #F6F7FA !important;
  font-size: 20px !important;
  line-height: 28px;
  margin-top: 36px;

  &:not(&:first-child) {
    margin-top: 40px;
  }
}

.bottom {
  margin-top: auto;
}

.social_media_items {
  margin-top: 12px;
}
